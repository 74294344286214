import React from "react";
import GenericModal from "components/MainApp/atoms/Modals/GenericModal";
import { Button } from "@material-ui/core";
import DownloadIcon from '@mui/icons-material/Download';

import "./styles.scss";
const PrintErrorModal = ({ open, handleClose, handleContinue, handleDownloadTags, showContinue = true}) => {
    const content = <div className="print-error-content">
        <div className="icon-div"><i className="icon-error icon-iconos_alerta"></i></div>
        <div className="print-error-title">Error en impresión</div>
        <Button
            className="color-upload"
            component="label"
            role={undefined}
            tabIndex={-1}
            startIcon={<DownloadIcon />}
            onClick={handleDownloadTags}
        >
            Descargar tags generados
        </Button>
        <div className="print-error-subtitle">Ha habido un error al imprimir</div>
        <div className="print-error-buttons">
          <div className="btn-wrapper">
              <Button className="btn-reimprimir" onClick={handleClose}>REINTENTAR</Button>

              {showContinue && <Button className="btn-close" onClick={handleContinue}>CONTINUAR</Button>}

          </div>
        </div>

    </div>
    return (
      <div className="print-error-container">
          <GenericModal
            open={open}
            content={content}
            handleClose={handleClose}></GenericModal>
      </div>
    );
}


export default PrintErrorModal;
