/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";
import { TagService } from "services/TagService";
import { ProductService } from "services/ProductService";

import { WSService } from "services/WSServices";
import { EventRepository } from "helpers/EventRepository";

import useRequest from "hooks/useRequest";

import "./styles.scss";
import AdminLayout from "../../../../components/MainApp/layouts/DesktopLayout";
import ThirdPartyTagAssociationStepOne from "./steps/Step1";
import ThirdPartyTagAssociationStepTwo from "./steps/Step2";
import TagAssociationFinished from "../../../../components/MainApp/organisms/Tags/TagAssociationFinished";
import Wizard from "../../../../components/MainApp/atoms/Wizard/StepProgress";
import RoundedButton from "../../../../components/MainApp/atoms/RoundedButton";
import Loader from "../../../../components/MainApp/atoms/Loader";
import TagAssociationModal from "components/MainApp/organisms/Tags/TagAssociationModal";
import data from "./data";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";
import { set } from "date-fns";

export const AssociateTagsView = props => {
  const history = useHistory();

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
    showError
  } = useRequest();
  const [activestep, setactivestep] = useState("0");

  // Step 0
  const [searchedValue, setsearchedValue] = useState("");
  const [readTags, setreadTags] = useState([]);
  const [prevreadTags, setprevreadTags] = useState([]);

  // Step 1
  const [tagsAssociated, settagsAssociated] = useState([]);
  const [tagsNotAssociated, settagsNotAssociated] = useState([]);
  const [allselectedTags, setallselectedTags] = useState(false);
  const [openTagAssociationForm, setopenTagAssociationForm] = useState(false);
  const [products, setproducts] = useState([]);
  const [productsOptions, setproductsOptions] = useState([]);
  const [fieldsProduct, setfieldsProduct] = useState([]);
  const [selectedProduct, setselectedProduct] = useState(null);
  const [selectedTags, setselectedTags] = useState("");
  const [errorModal, seterrorModal] = useState(false);
  const [errorMsg, seterrorMsg] = useState([]);

  const [errorMsgAux, seterrorMsgAux] = useState("");

  useEffect(() => {
    if (!readTags) {
      setprevreadTags([]);
      return;
    }
    if (readTags.length === 0) {
      setprevreadTags([]);
      return;
    }
  }, []);

  const uniqTags = array => {
    return array.sort().filter(function(item, pos, ary) {
      return !pos || item.rfid != ary[pos - 1].rfid;
    });
  };

  const uniqTagsByKey = array => {
    return array
      .sort((a, b) => {
        if (a.key > b.key) return -1;
        return 1;
      })
      .filter(function(item, pos, ary) {
        return !pos || item.key != ary[pos - 1].key;
      });
  };

  const removeDuplicates = array => {
    return array.filter(
      (value, index, self) =>
        index === self.findIndex(tag => tag.rfid === value.rfid)
    );
  };

  /*  useEffect(() => {
    let reads = uniqTags([...prevreadTags, ...readTags]);
    setreadTags([...reads])
  }, [prevreadTags])*/

  const mergeAndRemoveDuplicates = (prevreadTags, readTags) => {
    const combinedTags = [...prevreadTags, ...readTags];
    const uniqueTagsMap = new Map();
    combinedTags.forEach(tag => {
      uniqueTagsMap.set(tag.rfid, tag);
    });
    return Array.from(uniqueTagsMap.values());
  }

  useEffect(() => {
      const reads = mergeAndRemoveDuplicates(prevreadTags, readTags);
    /*     const maxTagRead = 10;
    if(reads.length > maxTagRead) {
      seterrorModal(true)
      let em = 'Superó la cantidad máxima de 10 tags leidos';
      seterrorMsg(em)
      return;
    } */
    setreadTags([...reads]);
  }, [prevreadTags]);

  const receiveEvent = useCallback(payload => {
    const ws = WSService.getInstance();
    console.log("Message from socket");
    console.log(payload);
    const newreads = [];
    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {
      processTag(payload.params.epc);
    }
  }, []);

  useEffect(() => {
    console.log(errorMsg);
  }, [errorMsg]);

  useEffect(() => {
    seterrorMsg(uniqTagsByKey([...errorMsg, errorMsgAux]));
    console.log(errorModal);
    console.log(errorMsg);
  }, [errorMsgAux]);

  const restart = () => {
    setactivestep("0");
    setreadTags([]);
    setprevreadTags([]);
    settagsAssociated([]);
    settagsNotAssociated([]);
    setallselectedTags(false);
    setopenTagAssociationForm(false);
    setproducts([]);
    setproductsOptions([]);
    setfieldsProduct([]);
    setselectedProduct(null);
    setselectedTags("");
  };

  const searchValue = e => {
    setsearchedValue(e.target.value.trim());
  };

  const processTag = (epc) => {
    let tag = {
      rfid: epc,
      epc: epc,
      barcode: null,
      sku: undefined,
      selected: false
    };
    TagService.tag(tag.rfid)
      .then(existingTag => {
        let em = (
          <div key={existingTag.rfid}>
            <br />
            El Tag <b>{existingTag.rfid} </b>
            ya se encuentra asociado a un producto. SKU: {existingTag.sku}
          </div>
        );
        seterrorMsgAux(em);
        seterrorModal(true);
        console.log("Tag ya asociado: " + existingTag.rfid);
        afterSubmit();
      })
      .catch(error => {
        console.log("No existe: " + tag.rfid);
        let newreads = [...readTags];
        newreads.push(tag);
        setprevreadTags([...prevreadTags, ...newreads]);
        console.log(error);
        afterSubmit();
      });
  }

  const handleSearch = e => {
    e.preventDefault();

    if (searchedValue) {
      const epc = searchedValue;
      beforeSubmit();
      processTag(epc);
      setsearchedValue("");
    }
  };

  const handleDelete = tag => {
    const tags = [...readTags];
    setreadTags(tags.filter(t => t.epc != tag.epc));
    setprevreadTags(tags.filter(t => t.epc != tag.epc));
  };

  const handleDeleteAll = e => {
    setreadTags([]);
    setprevreadTags([]);
  };

  const handleDeleteTag = tagToDelete => {
    let currentlyNotAssociated = [...tagsNotAssociated];
    let newtagsNotAssociated = [...tagsNotAssociated];
    let currentlyAssociated = [...tagsAssociated];
    let newtagsAssociated = [...tagsAssociated];

    for (let tag of currentlyNotAssociated) {
      if (tag === tagToDelete) {
        newtagsNotAssociated = newtagsNotAssociated.filter(
          t => t !== tagToDelete
        );
      }
    }

    for (let tag of currentlyAssociated) {
      if (tag === tagToDelete) {
        newtagsAssociated = newtagsAssociated.filter(t => t !== tagToDelete);
        newtagsNotAssociated.push(tagToDelete);
      }
    }
    settagsNotAssociated(newtagsNotAssociated);
    settagsAssociated(newtagsAssociated);
  };

  const handleSelectTag = tag => {
    const tags = [...tagsNotAssociated];
    for (let t of tags) {
      if (t === tag) {
        t.selected = !tag.selected;
        settagsNotAssociated(tags);
        break;
      }
    }
    const allSelected = tags.filter(t => t.selected == false).length === 0;
    setallselectedTags(allSelected);
  };

  const handleSelectAllTags = () => {
    const tags = [...tagsNotAssociated];

    for (let t of tags) {
      t.selected = !allselectedTags;
    }
    setallselectedTags(!allselectedTags);
    settagsNotAssociated(tags);
  };

  const associateTag = () => {
    setopenTagAssociationForm(true);
  };

  const handleSelectProduct = prod => {
    setselectedProduct(prod);
  };

  const handleAssociateTag = () => {
    const tagsCurrentlyNotAssociated = [...tagsNotAssociated];

    let newtagsNotAssociated = [...tagsNotAssociated];
    let newtagsAssociated = [...tagsAssociated];

    for (let tag of tagsCurrentlyNotAssociated) {
      if (tag.selected === true) {
        newtagsNotAssociated = newtagsNotAssociated.filter(t => t !== tag);
        tag.sku = selectedProduct.sku;
        newtagsAssociated.push(tag);
        tag.selected = false;
      }
    }
    settagsNotAssociated(newtagsNotAssociated);
    settagsAssociated(newtagsAssociated);
    setselectedProduct(null);
    setopenTagAssociationForm(false);
  };

  const stepChangeHandler = step => {
    setactivestep(step);
  };

  const nextStep = e => {
    e.preventDefault();
    const func = {
      "0": e => stepOneSend(e),
      "1": e => stepTwoSend(e),
      "2": e => handleSubmit(e)
    };
    func[activestep](e);
  };

  const stepOneSend = e => {
    settagsNotAssociated([...readTags]);
    if ([...readTags].length === 0) return false;

    settagsAssociated([]);
    e.preventDefault();
    beforeSubmit();
    stepChangeHandler("1");
    afterSubmit();
  };

  const stepTwoSend = e => {
    e.preventDefault();
    stepChangeHandler("2");
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Actualizo el SKU de cada TAG
    beforeSubmit();
    let templateId = null;
    let templatePromise = TagService.configTags()
      .then(response => {
        // elegir algun template existente en funcion de algun criterio
        if (response.length) templateId = response[0].id;
        else
          throw "No se encontraron templates de Tag. Primero debe crear un template de TAG cualquiera";
        return templateId;
      })
      .then(templateId => {
        tagsAssociated.forEach(tag => {
          let data = {
            rfid: tag.epc,
            sku: tag.sku,
            variables: {},
            external_tag: true,
            business: localStorage.getItem("business"),
            config_tag: templateId
          };
          TagService.createTag(data)
            .then(response => {
              console.log(response);
            })
            .catch(error => {
              console.error(error);
            });
        });
      })
      .then(() => {
        afterSubmit();
        setactivestep("finish");
      })
      .catch(error => {
        console.error(error);
        afterSubmit();
        //debugger
        //dealWithError(error,"Falló la asociacion del Tag");
        //useRequest.showError(e.message);
      });
  };

  const goBackFunc = () => {
    history.push("/admin/more-options-tags");
  };

  const goBackFunc2 = () => {
    stepChangeHandler("0");
  };

  useEffect(() => {
    if(tagsAssociated.length > 0 && tagsNotAssociated.length == 0) {
      setactivestep("2");
    }else if(tagsNotAssociated.length > 0){
      setactivestep("1");
    }else if(tagsAssociated.length == 0 && tagsNotAssociated.length == 0){
      setactivestep("0");
    }
    for (let tag of tagsNotAssociated) {
      if (tag.selected === true) {
        setselectedTags("enabled");
        return;
      }
    }
    setselectedTags("");
  }, [tagsNotAssociated]);

  const content = (
    <div className="associate-tags-content">
      {activestep !== "finish" ? (
        <div className="associate-tags-wrapper">
          <div className="associate-tags-wizard">
            <Wizard
              steps={data.steps}
              activeStep={activestep}
              clickEnabled={false}
            ></Wizard>
          </div>

          <div className="associate-tags-step">
            {activestep === "0" && (
              <div className="content">
                <ThirdPartyTagAssociationStepOne
                  readTags={readTags}
                  selectedTags={selectedTags}
                  searchValue={searchValue}
                  searchedValue={searchedValue}
                  handleSearch={handleSearch}
                  handleDelete={handleDelete}
                  handleDeleteAll={handleDeleteAll}
                  handleSend={nextStep}
                  enabledRead={true}
                  typeRead={"READ"}
                  associateRead={false}
                  priorityRead={"RFID"}
                  receiveEvent={receiveEvent}
                />
                <div className="load-movement-items-buttons-wrapper">
                  <div
                    className="load-movement-items-button load-movement-items-clean"
                    onClick={handleDeleteAll}
                  >
                    LIMPIAR
                  </div>
                  <div
                    className="load-movement-items-button load-movement-items-cancel"
                    onClick={goBackFunc}
                  >
                    CANCELAR
                  </div>
                  <div
                    className="load-movement-items-button load-movement-items-send"
                    onClick={nextStep}
                  >
                    CONTINUAR
                  </div>
                </div>
              </div>
            )}

            {activestep === "1" && (
              <div className="content">
                <ThirdPartyTagAssociationStepTwo
                  tagsAssociated={tagsAssociated}
                  tagsNotAssociated={tagsNotAssociated}
                  handleDelete={handleDeleteTag}
                  handleSelect={handleSelectTag}
                  allSelecteds={allselectedTags}
                  handleSelectAll={handleSelectAllTags}
                  goBackFunc={goBackFunc2}
                />
                <div className="load-movement-items-buttons-wrapper">
                  <div className="associate-tags-button">
                    <div
                        className="load-movement-items-button load-movement-items-cancel"
                        onClick={goBackFunc}
                      >
                      CANCELAR
                    </div>
                    <RoundedButton
                      legend="ASOCIAR A PRODUCTO"
                      state={selectedTags}
                      handleClick={associateTag}
                    ></RoundedButton>
                  </div>
                </div>
              </div>
            )}
            {activestep === "2" && (
              <div className="content">
                <ThirdPartyTagAssociationStepTwo
                  tagsAssociated={tagsAssociated}
                  tagsNotAssociated={tagsNotAssociated}
                  handleDelete={handleDeleteTag}
                  handleSelect={handleSelectTag}
                  allSelecteds={allselectedTags}
                  handleSelectAll={handleSelectAllTags}
                  goBackFunc={goBackFunc2}
                />
                <div className="load-movement-items-buttons-wrapper">
                  {tagsNotAssociated.length == 0 && (
                    <div className="associate-tags-button last">
                      <div
                        className="load-movement-items-button load-movement-items-cancel"
                        onClick={goBackFunc}
                      >
                      CANCELAR
                    </div>
                      <RoundedButton
                        legend="FINALIZAR"
                        state={(tagsAssociated.length !== 0)? "enabled": "disabled"}
                        handleClick={handleSubmit}
                      ></RoundedButton>
                    </div>
                  )}
                </div>
              </div>
            )}

          </div>
          {openTagAssociationForm && (
            <TagAssociationModal
              fields={fieldsProduct}
              handleClose={() => setopenTagAssociationForm(false)}
              handleSelect={handleSelectProduct}
              handleAssociateTag={handleAssociateTag}
            />
          )}
        </div>
      ) : (
        <div className="associate-tags-wrapper-finish">
          <div className="associate-tags-wrapper-finish-container">
            <TagAssociationFinished
              boldButtonFunc={restart}
              buttonLink={"/admin/config_tags/"}
            />
          </div>
        </div>
      )}
      <GenericErrorModal
        open={errorModal}
        handleClose={() => {
          seterrorMsg([]);
          seterrorMsgAux("");
          seterrorModal(false);
        }}
        error={errorMsg}
      ></GenericErrorModal>
    </div>
  );

  return (
    <div className="associate-tags-container">
      <AdminLayout
        headerTitle={"Asociación de Tags Externos"}
        headerOptions={data.headerOptions}
        content={content}
        goBackFunc={activestep !== "finish" ? goBackFunc : null}
        navHidden={true}
      ></AdminLayout>
      ll
      {loading && <Loader />}
    </div>
  );
};

export default AssociateTagsView;
