import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tippy";
import { isMobile } from "helpers/Mobile";
import {
  Checkbox
} from "@material-ui/core";

import "./styles.scss";

const tooltipStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
};



const TableItem = ({ item, columns, handleClick }) => {
  const renderPill = (column) => {
    const status = item[column.name];

    return <div className="generic-table-pill-container">
      <div className={`generic-table-pill`} >
        <p style={{'backgroundColor': column.pillMapColor[status]}}>{column.pillMap[status]}</p>
      </div>
    </div>
  }

  const renderColumn = (column) => {
    const colValue = item[column.name]
    return (
      <div
        className={`generic-table-item-${column.name} generic-table-item ${column.pill && 'pill'}`}
        data-item-id={column.name}
        onClick={() => handleClick(item.id)}
        key={column.name}
        style={{ display: column?.hideColumn ? 'none' : '' }}
      >
        {column.tooltip !== false ? (
        <Tooltip
          html={<div style={tooltipStyle}>
            {column.pill && <>{column.pillMap[colValue]}</>  }
            {!column.pill && <p>{colValue}</p> }
          </div>}
          followCursor={true}
          position="left"
          offset={25}
        >

            {column.pill && <>{renderPill(column)}</>  }
            {!column.pill && <p>
              {item?.checkBox && column?.checkbox && (
                <Checkbox
                  checked={item?.selected}
                  onChange={() => handleClick(item.id)}
                />
              )}
              {colValue}
            </p> }

        </Tooltip>
        ) : (
          <>
            {column.pill && <>{renderPill(column)}</>}
            {!column.pill && <p>
              {item?.checkBox && column?.checkbox && (
                <Checkbox
                  checked={item?.selected}
                  onChange={() => handleClick(item.id)}
                />
              )}
              {colValue}
            </p>}
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="generic-table-row"
      data-automation-id="generic-table-row"
    >
      {!isMobile && (
        <div
          className="generic-table-row-container"
          data-automation-id="row"
        >
          {columns.map((column) => {
            return renderColumn(column);
          })}


          <div
            className={`item-table-button`}
            data-automation-id="item-button"
            data-item-id={item.id}
            onClick={() => handleClick(item.id)}
            key={"button"}
          >
            <i className="icon-chevron"></i>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="generic-table-row-mobile" onClick={() => handleClick(item.id)}>
          {columns.map(
            column => {
              return <div key={column.name} className={`item-table-mobile ${column.pill && 'pill'}`}>
                {!column.pill &&
                <p>
                  {
                    item?.checkBox && column?.checkbox &&
                    <Checkbox
                      checked={item?.selected}
                      onChange={() => handleClick(item.id)}
                    />
                  }
                  {column.mobileTag && column.mobileTag} {item[column.name]}
                </p>}
                {column.pill && renderPill(column)}
              </div>
            }
          )}
        </div>
      )}
    </div>
  );
};

export default TableItem;
