import React from "react";
import MainAppGenericModal from "../GenericModal";

import "./styles.scss";

export class GenericErrorModal extends React.Component {

  render() {
    const {
      open,
      handleClose,
      error,
      showErrorIcon = false,
    } = this.props;

    const content = (<div className="error-category-modal-wrapper">
      {showErrorIcon &&
        <div className="icon-div">
          <i className="icon-error icon-iconos_alerta"></i>
        </div>
      }
      <div className="error-category-modal-text">{error}</div>
      <div className="error-category-modal-buttons">
        <div className="error-category-modal-button" onClick={handleClose} data-automation-id="confirm">ENTENDIDO</div>
      </div>
    </div>)
    return (
      <div className="error-category-modal-container">
          <MainAppGenericModal open={open} content={content} handleClose={handleClose}></MainAppGenericModal>
      </div>
    );
  }
}

GenericErrorModal.propTypes = {
};

export default GenericErrorModal;
