const columns = [
  {
    name: "check",
    pill: false,
    title: "",
    mobileTag: "Seleccionar",
    checkbox: true,
    tooltip: false,
  },
  {
    name: "id",
    pill: false,
    title: "",
    mobileTag: "",
    checkbox: false,
    hideColumn: true,
  },
  {
    name: "name",
    pill: false,
    title: "NOMBRE",
    mobileTag: "Nombre:",
  },
  {
    name: "tag",
    pill: false,
    title: "TAG",
    mobileTag: "Tag:",
  },
  {
    name: "lote",
    pill: false,
    title: "LOTE",
    mobileTag: "Lote:",
  },
  {
    name: "code",
    pill: false,
    title: "CÓDIGO",
    mobileTag: "Código:",
  },,
  {
    name: "fecha_vencimiento",
    pill: false,
    title: "FECHA VENCIMIENTO",
    mobileTag: "Fecha vencimiento:",
  },

];
export default columns;
