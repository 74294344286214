import React, { useState, useEffect, useRef } from "react";

import { isMobile } from "helpers/Mobile";
import useRequest from "hooks/useRequest";

import i18n from "i18n";
import Environment from "environment";

import Loader from "components/MainApp/atoms/Loader";
import MainDropdownInput from "components/MainApp/atoms/Forms/MainDropdown";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";

import "./styles.scss";
import { TagService } from "services/TagService";

import { useHistory } from "react-router-dom";
import { DeviceService } from "services/DeviceService";
import Search from "components/MainApp/atoms/Filters/Search";
import { EventRepository } from "helpers/EventRepository";
import {
  Button,
  styled,
} from "@material-ui/core";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import * as XLSX from "xlsx";
import GenericTable from "components/MainApp/organisms/GenericTable";
import columns from "./columns";
import BatchTagsErrorModal from "../AddTag/BatchTagsErrorModal";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";


const ReprintTagView = props => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const [tags, setTags] = useState([]);
  const [printers, setPrinters] = useState([]);
  const [printer, setPrinter] = useState(null);
  const [enableContinue, setEnableContinue] = useState(false);
  const [epc, setEpc] = useState();
  const [goToOrders, setGoToOrders] = useState(false);
  const [tagsFromFile, setTagsFromFile] = useState([]);
  const [showModalErrorFile, setShowModalErrorFile] = useState(false);
  const [errorPrintModal, setErrorPrintModal] = useState(false);
  const [errorFile, setErrorFile] = useState(null);
  const [checkSelectAll, setCheckSelectAll] = useState(false);
  const searchRef = useRef(null);

  const headerOptions = [];

  useEffect(() => {
    if (props.match.params.epc) {
      setGoToOrders(true);
      setEpc(props.match.params.epc)
      handleSearch(props.match.params.epc);
    }
    beforeSubmit();
    TagService.getTagsByBusiness()
      .then(tags => {
        setTags(tags);
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => {
        afterSubmit();
      });

    DeviceService.devices({ device_type: "PRINTER" }).then(data => {
      data = data.map(device => {
        return { label: device.name, option: device.id };
      });
      setPrinters(data);
    });


  }, []);

  useEffect(() => {
    setEnableContinue(printer && tagsFromFile.filter(tag => tag.selected).length > 0);
  }, [printer, tagsFromFile]);

  const validateTagsReturned = (tags, rfids_requested) => {
    setShowModalErrorFile(false);
    // si no están todos los tags que se han solicitado creamos fichero de errores
    const tags_returned = tags.map(tag => tag.rfid);
    const tags_not_returned = rfids_requested.filter(rfid => !tags_returned.includes(rfid));
    if(tags_not_returned.length > 0){
      const tags_not_returned2 = tags_not_returned.map(tag => {
        return {
          index: rfids_requested.findIndex(t => t === tag) + 2,
          tag: tag
        };
      });
      createErrorFile(tags_not_returned2);
      setShowModalErrorFile(true);
      return false;
    }
    return true;
  }

  const createErrorFile = (tags_not_returned) => {
    if (tags_not_returned.length > 0) {
      // Generar el fichero XLSX con los errores
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(
        tags_not_returned.map(tag => {
          return { fila: tag.index, error: `no encontrado el tag: ${tag.tag}` }
        })
      );
      XLSX.utils.book_append_sheet(wb, ws, "Errores");
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const errorFileUrl = URL.createObjectURL(blob);
      setErrorFile(errorFileUrl);
    }
  }

  const downloadErrorFile = () => {
    const link = document.createElement('a');
    link.href = errorFile;
    link.download = 'errores.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatDate = (date_expiration) => {
    if (!date_expiration) return "";
    const [year, month, day] = date_expiration.split('-');
    return `${day}/${month}/${year}`;
  }

  const processReturnTags = (response) => {
    const processTag = (tag) => ({
      checkBox: true,
      id: tag.rfid,
      name: tag.variables.name,
      tag: tag.rfid,
      lote: tag.variables.batch,
      code: tag.sku,
      fecha_vencimiento: formatDate(tag.variables.expiration),
      selected: false,
    });

    if (Array.isArray(response)) {
      const tags = response.map(processTag);
      setTagsFromFile((prevTags) => {
        const newTags = tags.filter(tag => !prevTags.some(prevTag => prevTag.id === tag.id));
        return [...prevTags, ...newTags];
      });
    } else {
      const tag = processTag(response);
      if(tagsFromFile.length === 0){
        tag.selected = true;
        setCheckSelectAll(true);
      }
      setTagsFromFile((prevTags) => {
        if (!prevTags.some(prevTag => prevTag.id === tag.id)) {
          return [...prevTags, tag];
        }
        return prevTags;
      });
    }
  };

  const handleSearch = epc => {
    if (epc) {
      setEpc(epc.trim().toUpperCase());
      beforeSubmit();
      TagService.tag(epc)
        .then(response => {
            processReturnTags(response);
            clearSearchBox();
        })
        .catch(error => {
          handleError(error);
          setEpc("");
          clearSearchBox();
        })
        .finally(() => {
          afterSubmit();
        });
    } else {
      setEpc("");
    }
  };

  const errorMsg = (msgError) => {
    EventRepository.notificationSend({
      label: msgError,
      type: "error"
    });
  }

  const handleError = error => {
    if (error?.status === 404) {
      EventRepository.notificationSend({
        label: i18n.t("No encontrado"),
        type: "error"
      });
    } else {
      dealWithError(error, "generic.error", (message, errors) => {
        if (Object.keys(errors).length === 0) {
          EventRepository.notificationSend({
            label: message,
            type: "error"
          });
        } else {
          EventRepository.notificationSend({
            label: i18n.t("Ha ocurrido un error."),
            type: "error"
          });
        }
      });
    }
  };

  const handleSelect = (selectedOption, type) => {
    setPrinter(selectedOption);
  };

  const handlePrint = e => {
    e.preventDefault();
    // imprimimos sólo los seleccionados
    const data = {
      device: printer.option,
      rfids: tagsFromFile.filter(tag => tag.selected).map(tag => tag.tag)
    };
    beforeSubmit();
    TagService.printTags(data)
      .then(tags => {
        EventRepository.notificationSend({
          label: i18n.t("Se imprimieron los tags de manera exitosa."),
          type: "success"
        });
      })
      .catch(error => {
        setErrorPrintModal(true);
        // handleError(error);
      })
      .finally(() => {
        afterSubmit();
      });

  };

  const validateFile = file => {
    // si el archivo no es xlsx o ods lanzamos error
    if (!file.name.match(/\.(xlsx|ods)$/)) {
      EventRepository.notificationSend({
        label: i18n.t(
          "El archivo de importación debe ser en formato excel / ods"
        ),
        type: "error"
      });
      return false;
    }
    return true;
  }

  const validateHeadersFile = (sheet) => {
    if (sheet["A1"]?.v?.toLowerCase() !== "código de producto" || sheet["B1"]?.v?.toLowerCase() !== "tag") {
      EventRepository.notificationSend({
        label: i18n.t( "El archivo debe tener las columnas CÓDIGO DE PRODUCTO y TAG"),
        type: "error"
      });
      return false;
    }
    return true;
  }

  const handleCheckboxChange = (event) => {
    const tagsFromFileChecked = tagsFromFile.map((tag) => ({ ...tag, selected: event.target.checked }));
    setCheckSelectAll(event.target.checked);
    setTagsFromFile(tagsFromFileChecked);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!validateFile(file)) return false;

    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      if (!validateHeadersFile(sheet)) return false;

      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      getTags(sheetData);
    };

    reader.readAsArrayBuffer(file);
  };

  const getTags = (sheetData) => {
    const rfids = sheetData.map((data) => data[1]);
    // quitamos la primera fila que son los headers
    rfids.shift();

    if(rfids.length === 0){
      errorMsg(i18n.t("No se encontraron tags"));
      return;
    }

    beforeSubmit();
    TagService.tags(rfids)
      .then(tags => {
        const validateRfids = validateTagsReturned(tags, rfids);
        if(!validateRfids) return;
        processReturnTags(tags);
      })
      .catch(error => {
        handleError(error);
      })
      .finally(() => {
        afterSubmit();
      });

  }

  const handleClickRow = (id) => {
    const updatedTagsFromFile = tagsFromFile.map((tag) =>
      tag.id === id ? { ...tag, selected: !tag.selected } : tag
    );
    setTagsFromFile(updatedTagsFromFile);
  }

  const clearSearchBox = () => {
    if (searchRef.current) {
      searchRef.current.instanceRef.cleanInput();
    }
  }
  const cleanTags = () => {
    setTagsFromFile([]);
    setCheckSelectAll(false);
    setEpc("");
    clearSearchBox();
  }

  const downloadTemplate = (e) => {
    e.preventDefault();
    window.open(Environment.statics + "files/plantilla.xlsx", "_blank");
  };

  const renderPrintPanel = () => (
    <div className="reprintTag-detail-panel-2-container">
      <div className="reprintTag-detail-panel-2">
        <div className="reprintTag-detail-section-title1">
          {i18n.t("Selecciona impresora")}
        </div>

        <MainDropdownInput
          required={true}
          id={"printer"}
          name="printer"
          selectedOption={printer ? printer : ""}
          handleSelection={selectedOption =>
            handleSelect(selectedOption, "printer")
          }
          maxLength={50}
          options={printers}
          placeholder={"Selecciona una impresora"}
          label={"Selecciona una impresora"}
        />
        <div className={"reprintTag-action-wrapper"}>
          <button
            type="submit"
            className={`reprintTag-action-button ${!enableContinue &&
              "disabled"}`}
            onClick={enableContinue ? handlePrint : undefined}
          >
            {i18n.t("Imprimir")}
          </button>
        </div>
      </div>
    </div>
  );

  const tableContent = (
    <div className={`reprintTag-content`}>
      {!isMobile && (
        <div className="reprintTag-wrapper-search">
          <Search
            resultList={tags}
            ref={searchRef}
            placeholder="Buscar tag"
            handleSubmit={handleSearch}
            value={epc}
          ></Search>
          <div>
            <Button
                className="color-btn"
                component="label"
                onClick={cleanTags}
              >
              limpiar
            </Button>
            <Button
              className="color-btn"
              component="label"
              role={undefined}
              tabIndex={-1}
              startIcon={<FileUploadOutlinedIcon />}
            >
              importar fichero
              <VisuallyHiddenInput
                type="file"
                accept=".xlsx,.ods"
                onChange={handleFileUpload}
                multiple
              />
            </Button>
            <Button
              component="label"
              className="color-btn"
              onClick={downloadTemplate}
            >
              <i className="icon-iconos_documento"></i>
              Info
            </Button>
          </div>
        </div>
      )}

      {tagsFromFile && tagsFromFile.length > 0 && (
        <div className="reprintTag-detail-container with-table">
          <div className="reprintTag-detail-panel-1-table">
            <GenericTable
              items={tagsFromFile}
              totalItems={tagsFromFile.length || 0}
              columns={columns}
              handleClick={handleClickRow}
              checkSelectAll={checkSelectAll}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
          {renderPrintPanel()}
        </div>
      )}
    </div>
  );

  const contentTable = tableContent;
  const content = (
    <React.Fragment>
      <div className="table-reprintTag-outter-wrapper">
        {isMobile && (
          <>
            <Search
              placeholder="Buscar por tag"
              resultList={tags}
              handleSubmit={handleSearch}
          />
          <div className="import-file-wrapper">
            <Button
              className="color-btn"
              component="label"
              onClick={cleanTags}
            >
              limpiar
            </Button>
            <Button
              className="color-btn"
              component="label"
              role={undefined}
              tabIndex={-1}
              startIcon={<FileUploadOutlinedIcon />}
            >
              importar fichero
              <VisuallyHiddenInput
                type="file"
                accept=".xlsx,.ods"
                onChange={handleFileUpload}
                multiple
              />
            </Button>
            <Button
              component="label"
              className="color-btn"
              onClick={downloadTemplate}
            >
              <i className="icon-iconos_documento"></i>
              Info
            </Button>
          </div>
        </>
        )}
        <div className="table-reprintTag-wrapper">{contentTable}</div>
      </div>
      <BatchTagsErrorModal
        open={showModalErrorFile}
        handleClose={() => setShowModalErrorFile(false)}
        handleDownload={downloadErrorFile}
      />
      <GenericErrorModal
        open={errorPrintModal}
        error={i18n.t("Error en impresión")}
        handleClose={() => setErrorPrintModal(false)}
        showErrorIcon={true}
      />

    </React.Fragment>
  );

  const goTo = () => {
    if(goToOrders){
      history.goBack();
    }else {
      history.push(`/admin/config_tags`);
    }
  };

  const headerTitle = "Reimpresión de Tags";

  return (
    <div className="reprintTag-container">
      <AdminLayout
        headerTitle={i18n.t(headerTitle)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={goTo}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default ReprintTagView;
